import './App.css';
import HomePage from './pages/Homepage';
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <ToastContainer autoClose={3000}/>
      <HomePage/>
    </div>
  );
}

export default App;
