import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Aos from 'aos';
import "aos/dist/aos.css";
import $ from "jquery";
import { IMaskInput } from "react-imask";
import './homepage.css';
import Logo from '../../image/aporteptroslogo.png';
import LogoFoter from '../../image/logo_old.svg';
import imagemBannerPrincipal from '../../image/imagem-banner-principal.png';
import BannerForm from '../../image/banner-form.png';
import MenuForm from '../../image/menu-form.png';
import menuDeducoes from '../../image/menu-deducoes.png';
import btnVoltar from '../../image/btn-voltar.svg';
import btnAvancar from '../../image/btn-avancar.svg';
import menuResultado from '../../image/menu-resultado.png';
import Interrogation from '../../image/interrogacao.png';
import parametros from '../../parametros';
import textimage from '../../image/textimage.png';
import { styled } from '@mui/material/styles';
import SegundoBg from '../../image/segundobg.png';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const URL_GERAR_BOLETO = "https://www.petros.com.br/PortalPetros/faces/Participantes/auto/fcontr/contresp"

export default function HomePage() {

    useEffect(() => {
        Aos.init({ duration: 2000 });
        document.title = 'Petros';
    }, [])

    var Scroll = require('react-scroll');
    var Element = Scroll.Element;
    var scroller = Scroll.scroller;

    const [nome, setNome] = useState("");
    const [matricula, setMatricula] = useState("");
    const [telefone, setTelefone] = useState("");


    const [primeiroForm, setPrimeiroForm] = useState(true);
    const [segundoFormulario, setSegundoForm] = useState(false);
    const [terceiroFormulario, setTerceiroFormulario] = useState(false);
    const [resultadoGeral, setResultadoGeral] = useState(false);
    const [prev, setPrev] = useState(false);
    const [conteinerBanner, setConteinerBanner] = useState(true);

    //CRIANDO OS STATE DOS INPUTS
    const [rendaBruta, setRendaBruta] = useState('');
    const [rendaBrutaFloat, setRendaBrutaFloat] = useState(0)

    const [inss, setInss] = useState("")
    const [ir, setIr] = useState("");
    const [ir2, setIr2] = useState("");

    const [previdenciaPrivada, setPrevidenciaPrivada] = useState('');
    const [previdenciaPrivadaFinal, setPrevidenciaPrivadaFinal] = useState('');
    const [baseCalculoIRPF, setBaseCalculoIRPF] = useState('');
    const [baseCalculoIRPF2, setBaseCalculoIRPF2] = useState("");
    const [totalAbatimentos, setTotalAbatimentos] = useState("0");

    const [numerosDependetes, setNumerosDependetes] = useState('');
    const [resultNumerosDependetes, setResultNumerosDependet] = useState('0');

    const [despesasMedicas, setDespesasMedicas] = useState('0');
    const [despesasAnualEnsinos, setDespesasAnualEnsinos] = useState('0');
    const [previdenciaSocial, setPrevidenciaSocial] = useState('0');
    const [resultPreviSocial, setResultPrevSocial] = useState("0");
    const [despesaAnualPensao, setDespesaAnualPensao] = useState('0');

    const [desenbolsoEfetAport, setDesenbolsoEfetAport] = useState("0");
    const [desenbolsoEfetivo, setDesenbolsoEfetetivo] = useState("0");
    const [aporteSerRealizado, setAporteSerRealizado] = useState("0");
    const [aporteParaBeneficio, setAportParaBeneficio] = useState("0");


    const [beneficio, setBeneficio] = useState("");
    const [beneficioTotal, setBeneficioTotal] = useState('');


    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);
    const [open6, setOpen6] = React.useState(false);
    const [open7, setOpen7] = React.useState(false);
    const [open8, setOpen8] = React.useState(false);
    const [open9, setOpen9] = React.useState(false);
    const [open10, setOpen10] = React.useState(false);
    const [open11, setOpen11] = React.useState(false);

    const [porcentagemAnual, setPorcentagelAnual] = useState('0')
    const handleTooltip = () => {
        setOpen(!open);
    };
    const handleTooltip2 = () => {
        setOpen2(!open2);
    };
    const handleTooltip3 = () => {
        setOpen3(!open3);
    };

    const handleTooltip4 = () => {
        setOpen4(!open4);
    };

    const handleTooltip5 = () => {
        setOpen5(!open5);
    };

    const handleTooltip6 = () => {
        setOpen6(!open6);
    };

    const handleTooltip7 = () => {
        setOpen7(!open7);
    };
    const handleTooltip8 = () => {
        setOpen8(!open8);
    };
    const handleTooltip9 = () => {
        setOpen9(!open9);
    };
    const handleTooltip10 = () => {
        setOpen10(!open10);
    };
    const handleTooltip11 = () => {
        setOpen11(!open11);
    };


    var aportSerRealido_ = 0

    //Funcão para colocar mascará nos inputs
    function formatReal(int) {
        var tmp = int + '';
        tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
        if (tmp.length > 6)
            tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        return tmp.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    function transFloat(value) {

        var palavra = value.toString();

        while (palavra.includes('.')) {
            palavra = palavra.replace('.', '')
        }
        return palavra.replace(',', '.')

    }

    function getBaseIRPF2(totalAbatimento) {
        const maxPrevPrivada = (parseFloat(((transFloat(rendaBruta) * 12 / 100))).toFixed(2));
        const aportSerRealido = (Math.max(maxPrevPrivada - transFloat(previdenciaPrivada))) > 0 ? (Math.max(maxPrevPrivada - transFloat(previdenciaPrivada))) : 0;
        aportSerRealido_ = aportSerRealido;
        setAportParaBeneficio(aportSerRealido);
        return ((parseFloat(transFloat(rendaBruta) - totalAbatimento)) - (aportSerRealido).toFixed(2));
    }

    function getTotalAbatimentos(resultPensao, previdenencia, resultDepentedes, desEnsino, desMedicas, desPrevPrivada) {




        const result = parseFloat(resultPensao)
            + parseFloat(previdenencia)
            + parseFloat(resultDepentedes)
            + parseFloat(desEnsino)
            + parseFloat(desMedicas)
            + parseFloat(desPrevPrivada);

        // const testeDespesaMedica = (parseFloat(desMedicas) + parseFloat(desPrevPrivada))
        return result

    }

    function getPensao(pensao, salary) {

        const salario = salary
        const valorPensao = pensao
        const result = Math.min((salario * parametros.aliq_max_pensao), valorPensao)

        return result
    }


    function queroSimular() {
        setConteinerBanner(false)
        setPrev(true);
    }

    function getValueDependets() {
        return parseFloat(numerosDependetes) * parametros.dedPorDependente > 0 ? parseFloat(numerosDependetes) * parametros.dedPorDependente : 0;
    }

    function getDesPorEnsino(value) {

        const valDedEducacao = Math.min(value, parametros.dedPoreducacao);

        return valDedEducacao < 0 ? "0,00" : valDedEducacao;

    }


    function getInss(salary, faixaSalarial) {
        if (faixaSalarial === 1) {
            if ((salary - parametros.inss_faxa1) < 0) return 0
        }

        if (faixaSalarial === 2) {
            return ((salary - parametros.inss_faxa1) * 7.5 / 100).toFixed(2);
        }

        if (faixaSalarial === 3) {

            const inss1 = parametros.inss_faxa1 * 7.5 / 100;
            const base1 = (Math.min((parametros.inss_faxa2 - parametros.inss_faxa1), (salary - parametros.inss_faxa2))).toFixed(2)
            const inss2 = base1 * 9 / 100;
            return (inss1 + inss2).toFixed(2);
            // alert(base1);
        }

        const inss1 = parametros.inss_faxa1 * 7.5 / 100;
        const base1 = (Math.min((parametros.inss_faxa2 - parametros.inss_faxa1), (salary - parametros.inss_faxa2))).toFixed(2)
        const base2 = (Math.min((parametros.inss_faxa3 - parametros.inss_faxa2), (salary - parametros.inss_faxa3))).toFixed(2)
        const base3 = (Math.min(salary, parametros.inss_teto) - parametros.inss_faxa3).toFixed(2)

        const inss2 = (base1 * 9 / 100);
        const inss3 = (base2 * 12 / 100);
        const inss4 = (base3 * 14 / 100);

        return (inss1 + inss2 + inss3 + inss4).toFixed(2);
    }
    function getIr(salary, faixaSalarial, irInicial) {

        const irInicial_ = (parseFloat(irInicial)).toFixed(2);
        const ir_ = Math.min(parametros.irpf_faxa1, irInicial_) * 0 / 100;
        const ir1_ = (Math.min((irInicial_ - parametros.irpf_faxa1), (parametros.irpf_faxa2 - parametros.irpf_faxa1)) * 7.5 / 100) > 0 ? (Math.min((irInicial_ - parametros.irpf_faxa1), (parametros.irpf_faxa2 - parametros.irpf_faxa1)) * 7.5 / 100) : 0;
        const ir2_ = (Math.min((irInicial_ - parametros.irpf_faxa2), (parametros.irpf_faxa3 - parametros.irpf_faxa2)) * 15 / 100) > 0 ? (Math.min((irInicial_ - parametros.irpf_faxa2), (parametros.irpf_faxa3 - parametros.irpf_faxa2)) * 15 / 100) : 0;
        const ir3_ = (Math.min((irInicial_ - parametros.irpf_faxa3), (parametros.irpf_faxa4 - parametros.irpf_faxa3)) * 22.5 / 100) > 0 ? (Math.min((irInicial_ - parametros.irpf_faxa3), (parametros.irpf_faxa4 - parametros.irpf_faxa3)) * 22.5 / 100) : 0;
        const ir4_ = (Math.max((irInicial_ - parametros.irpf_faxa4)) * 27.5 / 100) > 0 ? (Math.max((irInicial_ - parametros.irpf_faxa4)) * 27.5 / 100) : 0;

        return (ir_ + ir1_ + ir2_ + ir3_ + ir4_).toFixed(2);
    }

    function getIr2(irInicial) {

        const irInicial_ = (parseFloat(irInicial)).toFixed(2);
        const ir_ = Math.min(parametros.irpf_faxa1, irInicial_) * 0 / 100;
        const ir1_ = (Math.min((irInicial_ - parametros.irpf_faxa1), (parametros.irpf_faxa2 - parametros.irpf_faxa1)) * 7.5 / 100) > 0 ? (Math.min((irInicial_ - parametros.irpf_faxa1), (parametros.irpf_faxa2 - parametros.irpf_faxa1)) * 7.5 / 100) : 0;
        const ir2_ = (Math.min((irInicial_ - parametros.irpf_faxa2), (parametros.irpf_faxa3 - parametros.irpf_faxa2)) * 15 / 100) > 0 ? (Math.min((irInicial_ - parametros.irpf_faxa2), (parametros.irpf_faxa3 - parametros.irpf_faxa2)) * 15 / 100) : 0;
        const ir3_ = (Math.min((irInicial_ - parametros.irpf_faxa3), (parametros.irpf_faxa4 - parametros.irpf_faxa3)) * 22.5 / 100) > 0 ? (Math.min((irInicial_ - parametros.irpf_faxa3), (parametros.irpf_faxa4 - parametros.irpf_faxa3)) * 22.5 / 100) : 0;
        const ir4_ = (Math.max((irInicial_ - parametros.irpf_faxa4)) * 27.5 / 100) > 0 ? (Math.max((irInicial_ - parametros.irpf_faxa4)) * 27.5 / 100) : 0;

        return (ir_ + ir1_ + ir2_ + ir3_ + ir4_).toFixed(2);
    }
    function getBeneficio(irInicial) {



        const irInicial_ = (parseFloat(irInicial)).toFixed(2);
        const ir_ = Math.min(parametros.irpf_faxa1, irInicial_) * 0 / 100;
        const ir1_ = (Math.min((irInicial_ - parametros.irpf_faxa1), (parametros.irpf_faxa2 - parametros.irpf_faxa1)) * 7.5 / 100) > 0 ? (Math.min((irInicial_ - parametros.irpf_faxa1), (parametros.irpf_faxa2 - parametros.irpf_faxa1)) * 7.5 / 100) : 0;
        const ir2_ = (Math.min((irInicial_ - parametros.irpf_faxa2), (parametros.irpf_faxa3 - parametros.irpf_faxa2)) * 15 / 100) > 0 ? (Math.min((irInicial_ - parametros.irpf_faxa2), (parametros.irpf_faxa3 - parametros.irpf_faxa2)) * 15 / 100) : 0;
        const ir3_ = (Math.min((irInicial_ - parametros.irpf_faxa3), (parametros.irpf_faxa4 - parametros.irpf_faxa3)) * 22.5 / 100) > 0 ? (Math.min((irInicial_ - parametros.irpf_faxa3), (parametros.irpf_faxa4 - parametros.irpf_faxa3)) * 22.5 / 100) : 0;
        const ir4_ = (Math.max((irInicial_ - parametros.irpf_faxa4)) * 27.5 / 100) > 0 ? (Math.max((irInicial_ - parametros.irpf_faxa4)) * 27.5 / 100) : 0;


        return (ir_ + ir1_ + ir2_ + ir3_ + ir4_).toFixed(2);
    }

    function getSalaryRange(salary, tipo, irInicial) {

        if (tipo === 'inss') {
            if (salary < parametros.inss_faxa1) {

                const resultInns = getInss(salary, 1);
                return { inss: resultInns };
            }
            if (salary > parametros.inss_faxa1 && salary < parametros.inss_faxa2) {
                const resultInns = getInss(salary, 2);
                return { inss: resultInns };
            }
            if (salary > parametros.inss_faxa2 && salary < parametros.inss_faxa3) {

                const resultInns = getInss(salary, 3);
                return { inss: resultInns };
            }

            const resultInns = getInss(salary, 4);
            return { inss: resultInns };
        }

        if (tipo === 'ir') {
            if (salary < parametros.irpf_faxa1) {

                const resultIr = getIr(salary, 1, irInicial);

                return { ir: resultIr };
            }
            if (salary > parametros.irpf_faxa1 && salary < parametros.irpf_faxa2) {

                const resultIr = getIr(salary, 2, irInicial);
                return { ir: resultIr };
            }
            if (salary > parametros.irpf_faxa2 && salary < parametros.irpf_faxa3) {

                const resultIr = getIr(salary, 3, irInicial);
                return { ir: resultIr };
            }

            const resultIr = getIr(salary, 4, irInicial);
            return { ir: resultIr };
        }

        if (tipo === 'ir2') {

            if (salary < parametros.irpf_faxa1) {

                const resultIr2 = getIr2(irInicial);

                return { ir2: resultIr2 };
            }
            if (salary > parametros.irpf_faxa1 && salary < parametros.irpf_faxa2) {

                const resultIr2 = getIr2(irInicial);
                return { ir2: resultIr2 };
            }
            if (salary > parametros.irpf_faxa2 && salary < parametros.irpf_faxa3) {

                const resultIr2 = getIr2(irInicial);
                return { ir2: resultIr2 };
            }

            const resultIr2 = getIr2(irInicial);
            return { ir2: resultIr2 };
        }

        if (tipo === 'beneficio') {

            if (salary < parametros.irpf_faxa1) {

                const beneficio = getBeneficio(irInicial);

                return { beneficio: beneficio };
            }
            if (salary > parametros.irpf_faxa1 && salary < parametros.irpf_faxa2) {

                const beneficio = getBeneficio(irInicial);
                return { beneficio: beneficio };
            }
            if (salary > parametros.irpf_faxa2 && salary < parametros.irpf_faxa3) {

                const beneficio = getBeneficio(irInicial);
                return { beneficio: beneficio };
            }

            const beneficio = getBeneficio(irInicial);
            return { beneficio: beneficio };
        }

    }

    function calculadora() {

        var rendaMensal = (transFloat(rendaBruta) / 12);

        const { inss } = getSalaryRange(rendaMensal, 'inss')
        setInss(inss);

        const resultDepentedes = getValueDependets(numerosDependetes)
        setResultNumerosDependet(resultDepentedes);

        const desEnsino = getDesPorEnsino(transFloat(despesasAnualEnsinos));
        setDespesasAnualEnsinos(desEnsino)

        const desMedicas = despesasMedicas != '' ? despesasMedicas : "0,00";
        setDespesasMedicas(desMedicas)

        const resultPensao = getPensao(transFloat(despesaAnualPensao), transFloat(rendaBruta));

        setDespesaAnualPensao(resultPensao);

        const totalAbatimento = getTotalAbatimentos(resultPensao, transFloat(previdenciaSocial), resultDepentedes, desEnsino, transFloat(desMedicas), transFloat(previdenciaPrivada));
        setTotalAbatimentos(totalAbatimento);


        const previdenciaPrivada_ = transFloat(previdenciaPrivada)
        const aporte_ser_realizado = Math.max(parseFloat((transFloat(rendaBruta)) * 12 / 100) - parseFloat(previdenciaPrivada_), "0");
        setAporteSerRealizado(aporte_ser_realizado)

        const baseCalculoIRPF_ = transFloat(rendaBruta) - totalAbatimento
        setBaseCalculoIRPF(baseCalculoIRPF_);
        const baseCalculoIRPF2_ = getBaseIRPF2(totalAbatimento)
        setBaseCalculoIRPF2(baseCalculoIRPF2_)

        const contribuAnualPrevPrivada = aporte_ser_realizado + parseFloat(previdenciaPrivada_)
        setPrevidenciaPrivadaFinal(contribuAnualPrevPrivada)


        const irInicial = (parseFloat(transFloat(rendaBruta)) - totalAbatimento);

        const { ir } = getSalaryRange(rendaMensal, 'ir', irInicial);
        const irSet = parseFloat(ir)
        setIr(irSet);

        const irInicial2 = getBaseIRPF2(totalAbatimento);

        const { ir2 } = getSalaryRange(rendaMensal, 'ir2', irInicial2);
        const ir2Set = parseFloat(ir2);
        setIr2(ir2Set);

        const diferenca = parseFloat(ir) - parseFloat(ir2)
        const desenbolso = (aporte_ser_realizado) - diferenca

        setDesenbolsoEfetetivo(desenbolso);

        const beneficioBase = transFloat(rendaBruta) - (totalAbatimento - transFloat(previdenciaPrivada));
        const { beneficio } = getSalaryRange(rendaMensal, 'beneficio', beneficioBase);

        const beneficio_ = beneficio - ir
        setBeneficio(beneficio_);

        const beneficioTotal_ = (ir - ir2) + beneficio_
        setBeneficioTotal(beneficioTotal_)

        const resultBeneficioTotal = parseFloat(transFloat(previdenciaPrivada)) + aporte_ser_realizado
        setPrevidenciaPrivadaFinal(resultBeneficioTotal);
    }

    function primeiroFormulario(e) {

        e.preventDefault();

        if (rendaBruta === "" || previdenciaPrivada === "") {
            toast.warn("Por favor, preecher todos os campos");
            return;
        }

        setPrimeiroForm(false);
        setSegundoForm(true);
    }

    function btnQueroDeducoes() {
        setSegundoForm(false);
        setTerceiroFormulario(true);

    }

    function geraResultados() {
        setSegundoForm(false);
        setResultadoGeral(true);
        calculadora();
    }

    //Funcão que efetua o calculo final
    function tercerioFormulario(e) {
        e.preventDefault();

        setDespesaAnualPensao(despesaAnualPensao);


        setTerceiroFormulario(false);
        setResultadoGeral(true);
        calculadora();
    }

    function btnVoltarForm2() {

        if (segundoFormulario) {
            setSegundoForm(false);
            setPrimeiroForm(true);
        }
    }
    function btnVoltarForm3() {
        if (terceiroFormulario) {
            setTerceiroFormulario(false);
            setSegundoForm(true);
        }
    }
    function btnVoltarFormResult() {

        if (resultadoGeral) {
            setResultadoGeral(false);
            setSegundoForm(true);

        }

    }

    function clickScreen() {
        setOpen(false)
        setOpen2(false)
        setOpen3(false)
        setOpen4(false)
        setOpen5(false)
        setOpen6(false)
        setOpen7(false)
        setOpen8(false)
        setOpen9(false)
        setOpen10(false)
        setOpen11(false)

    }

    function handlePorcentagemAnual() {
        const calcPorcentagemAnual = ((rendaBruta / 12) * 100) / rendaBruta
        setPorcentagelAnual(calcPorcentagemAnual)
    }

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 200,
            background: '#A8CAC7',
            color: 'black',
            borderRadius: 0,
            fontSize: '13px',
            padding: 10
        },
    });
    const CustomMaxWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
            background: '#A8CAC7',
            color: 'black',
            borderRadius: 0,
            fontSize: '13px',
            padding: 10
        },
    });

    return (
        <div className="container-fluid">

            {conteinerBanner ?
                <div>
                    <div className='banner-principal-novo display-mobile'>
                        <div className='container-text-principal'>
                            <div>
                                <div>
                                    <a href="https://www.petros.com.br/" target="_blank" rel="noreferrer">
                                        <img src={Logo} alt="Logo - Petros" style={{ width: '120px' }} />
                                    </a>
                                </div>
                                <div className='mt-5'>
                                    <img src={textimage} alt="Frase" className='text-image img-fluid' />
                                </div>
                            </div>
                            <div style={{alignItems: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                <p style={{color: '#666666', textAlign: 'center'}}>
                                    Faça um <b style={{ color: '#de8e28' }}>aporte até 26/12</b> e<br/> aproveite as <b style={{ color: '#de8e28' }}>vantagens do<br/> seu plano</b> de previdência.
                                </p>
                                <button onClick={queroSimular} className='mt-5' >QUERO SIMULAR</button>
                            </div>
                        </div>
                        <div className='imagem-banner-principal'>
                            <img src={imagemBannerPrincipal} ></img>
                        </div>
                    </div>

                    <div className='container-mobile-principal' style={{ display: 'none' }} >
                        <div className='container-btn-mobile' >
                            <button className='btn-mobile-principal' onClick={queroSimular} >QUERO SIMULAR</button>
                        </div>
                    </div>

                </div>

                //     <div className='container-text-banner'>
                //         <div className='logo-banner'>
                //             <a href="https://www.petros.com.br/" target="_blank" rel="noreferrer">
                //                 <img src={Logo} alt="Logo - Petros" className='img-logo-banner' />
                //             </a>
                //         </div>
                //         <div className='titulo-banner'>
                //             <img src={textimage} alt="Frase" className='text-image' />
                //         </div>
                //         <div >
                //             <h1> Faça já <br /> o seu aporte.
                //             </h1>

                //             <p>
                //                 Aumente seu saldo  <br />  acumulado e ganhe <br />
                //                 <span style={{ color: '#17847B' }}>benefício fiscal no<br /> Imposto de Renda 2024.</span>
                //             </p>
                //             <button onClick={queroSimular} >QUERO SIMULAR</button>
                //         </div>
                //     </div>
                // <div className="conteudo-principal">

                //     <div className='container-text-banner'>
                //         <div className='logo-banner'>
                //             <a href="https://www.petros.com.br/" target="_blank" rel="noreferrer">
                //                 <img src={Logo} alt="Logo - Petros" className='img-logo-banner' />
                //             </a>
                //         </div>
                //         <div className='titulo-banner'>
                //             <img src={textimage} alt="Frase" className='text-image' />
                //         </div>
                //         <div >
                //             <h1> Faça já <br /> o seu aporte.
                //             </h1>

                //             <p>
                //                 Aumente seu saldo  <br />  acumulado e ganhe <br />
                //                 <span style={{ color: '#17847B' }}>benefício fiscal no<br /> Imposto de Renda 2024.</span>
                //             </p>
                //             <button onClick={queroSimular} >QUERO SIMULAR</button>
                //         </div>
                //     </div>

                //     {/* <div className='container-text-banner'>
                //         <div className="logo">
                //             <a href="https://www.petros.com.br/" target="_blank" rel="noreferrer">
                //                 <img src={Logo} alt="Logo - Petros" />
                //             </a>
                //         </div>
                //         <div className='txtimage-container'>
                //             <img src={textimage} alt="Frase" className='text-image' />

                //         </div>
                //     </div>
                //     <div className="texto-principal">
                //         <h1> Faça já <br /> o seu aporte.
                //         </h1>

                //         <p>
                //             Aumente seu saldo  <br />  acumulado e ganhe <br />
                //             <span style={{ color: '#17847B' }}>benefício fiscal no<br /> Imposto de Renda 2024.</span>
                //         </p>
                //         <button onClick={queroSimular} >QUERO SIMULAR</button>
                //     </div> */}
                // </div>
                : false}
            {/* FIM do trecho - Conteúdo da primeira div */}
            {prev ?

                <div className="background-form">
                    {primeiroForm ?
                        <div className="container-form-inicio">
                            <div className="container-form">
                                <div className='img-mobile'>
                                    <img src={SegundoBg} alt='' style={{ display: 'none' }} ></img>
                                </div>
                                <div className="form-principal" >
                                    <div className="menu-img">
                                        <img src={MenuForm} alt="" />
                                    </div>
                                    <h2>Vamos começar?</h2>

                                    <hr />

                                    <form className="form" onSubmit={primeiroFormulario}>
                                        <div className='tooltipStyle'>

                                            <label>Informe a sua renda bruta anual:</label>
                                            <CustomWidthTooltip open={open} title='Informe a soma de todos os rendimentos recebidos ao longo do ano.' placement='right'>
                                                <img onClick={handleTooltip} src={Interrogation} style={{ display: 'block', width: '100%', maxWidth: '18px' }}></img>
                                            </CustomWidthTooltip>
                                        </div>
                                        <input type="text"
                                            placeholder="R$"
                                            value={"R$ " + rendaBruta.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                                            onChange={(e) => {
                                                var str = parseInt(e.target.value.replace(/[\D]+/g, ''));
                                                const val = formatReal(str)
                                                if (val === "NaN") {
                                                    setRendaBruta("0");
                                                } else {
                                                    setRendaBruta(String(val));
                                                }
                                            }}
                                        />
                                        <div className='tooltipStyle'>

                                            <label>Qual o valor anual pago de previdência?</label>
                                            <CustomWidthTooltip open={open2} title='• Informe neste campo o valor total de contribuições que você já pagou de previdência privada no ano. 
                                                     • A dedução é possível somente na Declaração Completa de Imposto de Renda, desde que também haja contribuição para o INSS. 
                                               •  Se houver contribuições de patrocinador ou empregador, elas não devem ser incluídas. ' placement='right' >
                                                <img onClick={handleTooltip2} src={Interrogation} style={{ display: 'block', width: '100%', maxWidth: '18px' }}></img>
                                            </CustomWidthTooltip >

                                        </div>
                                        <input type="text"
                                            placeholder="R$"
                                            value={"R$ " + previdenciaPrivada.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                                            onChange={(e) => {
                                                var str = parseInt(e.target.value.replace(/[\D]+/g, ''));
                                                const val = formatReal(str)
                                                if (val === "NaN") {
                                                    setPrevidenciaPrivada("0");
                                                } else {
                                                    setPrevidenciaPrivada(String(val));
                                                }
                                            }}
                                        />
                                        <span>Para conferir a sua renda bruta anual e o valor anual pago de previdência, consulte os seus contracheques deste ano.</span>

                                        <button type="submit">Continuar </button>
                                    </form>
                                </div>
                                <div className="form-img">
                                    <img src={SegundoBg} alt="Mão com moedas solicitando efetuar a simulação" />
                                </div>

                            </div>
                        </div>
                        : false}


                    {segundoFormulario ?

                        <div className="container-form-inicio">
                            <div className="container-form">
                                <div className='img-mobile'>
                                    <img src={SegundoBg} alt='' style={{ display: 'none' }} ></img>
                                </div>
                                <div className="form-principal" >
                                    <div className="menu-img">
                                        <img src={MenuForm} alt="" />
                                    </div>
                                    <h2>Quer incluir outras <br />deduções na simulação?</h2>

                                    <hr />
                                    <div className="container-button">
                                        <button onClick={btnQueroDeducoes} >Quero <img src={btnAvancar} alt="botão para avançar, eu quero" /></button>
                                        <button onClick={geraResultados} >Não Quero <img src={btnAvancar} alt="botão de acançar, não quero" /></button>
                                    </div>
                                    <div className="btn-voltar">
                                        <button onClick={btnVoltarForm2}> <img src={btnVoltar} alt="botão de voltar" /> </button>
                                        <p>Voltar</p>
                                    </div>
                                </div>
                                <div className="form-img">
                                    <img src={SegundoBg} alt="Mão com moedas solicitando efetuar a simulação" />
                                </div>
                            </div>
                        </div>
                        : false}


                    {terceiroFormulario ?
                        <div className="container-form-inicio" style={{ height: 'auto' }}>
                            <div className="container-form-terceiro">
                                <div className='img-mobile'>
                                    <img src={SegundoBg} alt='' style={{ display: 'none' }} ></img>
                                </div>
                                <div className="form-principal" >
                                    <div className="menu-img">
                                        <img src={menuDeducoes} alt="" />
                                    </div>
                                    <h2>Preencha os campos abaixo <br /> e vamos em frente!</h2>

                                    <hr />

                                    <form className="form" onSubmit={tercerioFormulario}>
                                        <div className='tooltipStyle'>

                                            <label>Número de dependentes:</label>
                                            <CustomWidthTooltip open={open3} title='Dedução limitada a R$ 2.275,08 por dependente.' placement='right'>
                                                <img onClick={handleTooltip3} src={Interrogation} style={{ display: 'block', width: '100%', maxWidth: '18px' }}></img>
                                            </CustomWidthTooltip>
                                        </div>
                                        <input type="text"
                                            value={numerosDependetes}
                                            onChange={(e) => {
                                                setNumerosDependetes(e.target.value)
                                            }}
                                        />
                                        <div className='tooltipStyle'>

                                            <label>Despesas médicas:</label>
                                            <CustomWidthTooltip open={open4} title='Podem ser deduzidas integralmente.' placement='right'>
                                                <img onClick={handleTooltip4} src={Interrogation} style={{ display: 'block', width: '100%', maxWidth: '18px' }}></img>
                                            </CustomWidthTooltip>
                                        </div>
                                        <input type="text"
                                            placeholder="R$"
                                            value={"R$ " + despesasMedicas.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                                            onChange={(e) => {
                                                var str = parseInt(e.target.value.replace(/[\D]+/g, ''));
                                                const val = formatReal(str)
                                                if (val === "NaN" || val === "") {
                                                    setDespesasMedicas("0");
                                                } else {
                                                    setDespesasMedicas(String(val));
                                                }
                                            }}
                                        />
                                        <div className='tooltipStyle'>

                                            <label>Despesa anual com ensino:</label>
                                            <CustomWidthTooltip open={open5} title='Têm o limite individual, por declarante e dependentes, de R$ 3.561,50 ao ano.' placement='right'>
                                                <img onClick={handleTooltip5} src={Interrogation} style={{ display: 'block', width: '100%', maxWidth: '18px' }}></img>
                                            </CustomWidthTooltip>
                                        </div>
                                        <input type="text"
                                            placeholder="R$"
                                            value={"R$ " + despesasAnualEnsinos.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                                            onChange={(e) => {

                                                var str = parseInt(e.target.value.replace(/[\D]+/g, ''));
                                                const val = formatReal(str)
                                                if (val === "NaN" || val === "") {
                                                    setDespesasAnualEnsinos("0");
                                                } else {
                                                    setDespesasAnualEnsinos(String(val));
                                                }
                                            }}
                                        />
                                        <div className='tooltipStyle'>

                                            <label>Previdência social (INSS):</label>
                                            <CustomWidthTooltip open={open6} title='Informe o total pago de INSS no ano.' placement='right'>
                                                <img onClick={handleTooltip6} src={Interrogation} style={{ display: 'block', width: '100%', maxWidth: '18px' }}></img>
                                            </CustomWidthTooltip>
                                        </div>
                                        <input type="text"
                                            placeholder="R$"
                                            value={"R$ " + previdenciaSocial.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                                            onChange={(e) => {
                                                var str = parseInt(e.target.value.replace(/[\D]+/g, ''));
                                                const val = formatReal(str)
                                                if (val === "NaN" || val === "") {
                                                    setPrevidenciaSocial("0");
                                                } else {
                                                    setPrevidenciaSocial(String(val));
                                                }
                                            }}
                                        />
                                        <div className='tooltipStyle'>

                                            <label>Despesa anual com pensão alimentícia:</label>
                                            <CustomWidthTooltip open={open7} title='A despesa de pensão alimentícia, homologada por decisão judicial, não poderá ser maior do que 100% dos seus rendimentos brutos.' placement='right'>
                                                <img onClick={handleTooltip7} src={Interrogation} style={{ display: 'block', width: '100%', maxWidth: '18px' }}></img>
                                            </CustomWidthTooltip>
                                        </div>
                                        <input type="text"
                                            placeholder="R$"
                                            value={"R$ " + despesaAnualPensao.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                                            onChange={(e) => {

                                                var str = parseInt(e.target.value.replace(/[\D]+/g, ''));

                                                const val = formatReal(str)
                                                if (val === "NaN" || val === "") {
                                                    setDespesaAnualPensao("0");

                                                } else {
                                                    setDespesaAnualPensao(String(val));
                                                }
                                            }}
                                        />
                                        <button type="submit" className="btn-form-terceiro">Simular Agora <img src={btnAvancar} alt="Botão continuar" /> </button>
                                    </form>
                                    <div className="btn-voltar">
                                        <button onClick={btnVoltarForm3} > <img src={btnVoltar} alt="botão de voltar" /> </button>
                                        <p>Voltar</p>
                                    </div>
                                </div>
                                <div className="form-img">
                                    <img src={SegundoBg} alt="Mão com moedas solicitando efetuar a simulação" />
                                </div>
                            </div>
                        </div>
                        : false}

                    {resultadoGeral ?
                        <div className="container-form-inicio" style={{ height: 'auto' }}>
                            <div className="container-form-resultado">
                                <div className="container-header-resultado">
                                    <div className="form-principal" >
                                        <div className='img-mobile'>
                                            <img src={SegundoBg} alt='' style={{ display: 'none' }} ></img>
                                        </div>
                                        <div className="menu-img">
                                            <img src={menuResultado} alt="" />
                                        </div>
                                        <h2>Você pode ganhar<br />o benefício fiscal. </h2>
                                        <hr />
                                        <div className="text-result">
                                            <p>Faça o aporte de</p>
                                            <strong><p>R$ {aporteParaBeneficio.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</p></strong>
                                            <hr />
                                            <p>E o valor total do seu benefício fiscal será de</p>
                                            <p><strong>R$ {beneficioTotal.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</strong></p>
                                            <hr />
                                            <a className="gerar-boleto" href={URL_GERAR_BOLETO} target="_blank" rel="noreferrer">Gerar boleto</a>
                                        </div>
                                    </div>
                                    <div className="form-img">
                                        <img src={SegundoBg} alt="Mão com moedas solicitando efetuar a simulação" />
                                    </div>
                                </div>
                                <div className="tabela-resultado">
                                    <h2>Confira a análise da sua simulação</h2>
                                    <p>Veja como na declaração de IR no modelo completo, considerando suas contribuições para previdência, o valor do imposto será menor. </p>
                                    <table border="0">
                                        <thead>
                                            <tr>
                                                <td>Dados</td>
                                                <td>Situação atual</td>
                                                <td>Contribuição para ganhar <br /> o benefício de 12%</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Rendimento anual</td>
                                                <td>R$ {rendaBruta.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                                <td>R$ {rendaBruta.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td>Dedução com dependentes</td>
                                                <td>R$ {resultNumerosDependetes.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                                <td>R$ {resultNumerosDependetes.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td>Previdência Social (INSS)</td>
                                                <td>R$ {previdenciaSocial.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                                <td>R$ {previdenciaSocial.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td>Despesas médicas</td>
                                                <td>R$ {despesasMedicas.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                                <td>R$ {despesasMedicas.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td>Despesas com ensino</td>
                                                <td>R$ {despesasAnualEnsinos.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                                <td>R$ {despesasAnualEnsinos.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>

                                            </tr>
                                            <tr>
                                                <td>Pensão alimentícia</td>
                                                <td>R$ {despesaAnualPensao.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                                <td>R$ {despesaAnualPensao.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td>Aporte para benefício fiscal</td>
                                                <td>R$ 0,00</td>
                                                <td>R$ {aporteParaBeneficio.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className='tooltipStyle'>

                                                        <label>Contribuição anual para a previdência privada</label>
                                                        <CustomWidthTooltip open={open8} title='São limitadas a 12% do total de rendimentos.' placement='right'>
                                                            <img onClick={handleTooltip8} src={Interrogation} style={{ display: 'block', width: '100%', maxWidth: '18px' }}></img>
                                                        </CustomWidthTooltip>
                                                    </div>
                                                </td>
                                                <td>R$ {previdenciaPrivada.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                                <td>R$ {previdenciaPrivadaFinal.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className='tooltipStyle'>

                                                        <label>Base de cálculo do imposto de renda</label>
                                                        <CustomWidthTooltip open={open9} title='Valor do total dos seus rendimentos, já deduzidas as despesas informadas, observando os limites de cada uma delas.' placement='right'>
                                                            <img onClick={handleTooltip9} src={Interrogation} style={{ display: 'block', width: '100%', maxWidth: '18px' }}></img>
                                                        </CustomWidthTooltip>
                                                    </div>
                                                </td>
                                                <td>R$ {baseCalculoIRPF.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                                <td>R$ {baseCalculoIRPF2.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td>Imposto devido</td>
                                                <td>R$ {ir.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                                <td>R$ {ir2.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className='tooltipStyle'>

                                                        <label>Benefício fiscal</label>
                                                        <CustomWidthTooltip open={open10} title='É o valor de imposto de renda que você deixa de pagar ao contribuir para a previdência privada.' placement='right'>
                                                            <img onClick={handleTooltip10} src={Interrogation} style={{ display: 'block', width: '100%', maxWidth: '18px' }}></img>
                                                        </CustomWidthTooltip>
                                                    </div>
                                                </td>
                                                <td>R$ {beneficio.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                                <td>R$ {beneficioTotal.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className='tooltipStyle'>

                                                        <label>Desembolso efetivo </label>
                                                        < CustomMaxWidthTooltip open={open11} title=
                                                            {`Considerando o seu rendimento bruto anual, 
                                                              você poderá fazer contribuição de R$ ${previdenciaPrivadaFinal.toLocaleString('pt-br', { minimumFractionDigits: 2 })} ao seu plano de previdência para aproveitar ao máximo a dedução de 12%,
                                                              no modelo de declaração completa do IR. Para alcançar esse valor, 
                                                              faça um aporte adicional de R$ ${aporteParaBeneficio.toLocaleString('pt-br', { minimumFractionDigits: 2 })}.
                                                              Dessa forma, além de aumentar sua reserva previdenciária no valor do aporte,                                                              
                                                              você terá uma economia de R$ ${(beneficioTotal - beneficio).toLocaleString('pt-br', { minimumFractionDigits: 2 })}  no valor do imposto devido.`}
                                                            placement='right'>
                                                            <img onClick={handleTooltip11} src={Interrogation} style={{ display: 'block', width: '100%', maxWidth: '18px' }}></img>
                                                        </ CustomMaxWidthTooltip>
                                                        {/* < CustomMaxWidthTooltip open={open11} title={`Considerando a situação atual de ${porcentagemAnual}% de contribuição anual, você terá feito, no ano uma poupança previdenciária de R$ XXXXXX, sendo que o desembolso efetivo será de apenas R$ X.XXXX, pois
                                                                                        deixará de pagar R$ X.XXX de IR.
                                                                                        Considerando contribuição anual para benefício fiscal
                                                                                         máximo 12%, você terá feito no ano uma poupança
                                                                                          previdenciária de R$ XXXXX, sendo que o desembolso efetivo será 
                                                                                          de apenas R$ XXXXX, pois deixará de pagar R$ XXXXX de IR.`} placement='right'>
                                                            <img onClick={handleTooltip11} src={Interrogation} style={{ display: 'block', width: '100%', maxWidth: '18px' }}></img>
                                                        </ CustomMaxWidthTooltip> */}
                                                    </div>
                                                </td>
                                                <td>R$ {desenbolsoEfetAport.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                                <td>R$ {desenbolsoEfetivo.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className='tableFooter'>

                                        <a className="buttonsFooter" href={URL_GERAR_BOLETO} target="_blank" rel="noreferrer">Gerar boleto</a>

                                        <a
                                            className="buttonsFooter"
                                            href="#"
                                            rel="noreferrer"
                                            style={{ backgroundColor: '#81B3B0' }}
                                            onClick={() => window.location.reload()}
                                        >
                                            Nova simulação
                                        </a>
                                    </div>
                                </div>
                                <div className="btn-voltar">
                                    <button onClick={btnVoltarFormResult}> <img src={btnVoltar} alt="botão de voltar" /> </button>
                                    <p>Voltar</p>
                                </div>
                            </div>
                        </div>
                        : false}


                    {/* <div className="conteudo-footer">
                        <a href="https://www.petros.com.br/" target="_blank" rel="noreferrer">
                            <div className="logo-footer">
                                <img src={Logo} alt="Logo - Petros" />
                            </div>
                        </a>
                        <div className="texto-footer">
                            <h3>Ficou com alguma dúvida?</h3>
                            <p>Preecha com seus dados e a gente entra em contato com você.</p>
                        </div>
                        <form className="form-footer" onSubmit={salvarContato}>
                            <label>Nome</label>
                            <input type="text"
                                placeholder="Digite seu nome..."
                                value={nome}
                                onChange={(e) => { setNome(e.target.value); }} />
                            <label> Matrícula Petros (apenas números):</label>
                            <input type="text"
                                placeholder="Digite sua matricula..."
                                value={matricula}
                                onChange={(e) => { setMatricula(e.target.value) }}
                            />
                            <label>Telefone para contato (com ddd):</label>
                            <IMaskInput
                               mask="(00) 00000-0000"
                                type="text"
                                placeholder="Digite seu telefone..."
                                value={telefone}
                                onChange={(e) => { setTelefone(e.target.value) }} />
                                                    
                            <button type="submit">Continuar <img src={btnAvancar} alt="Botão continuar" /> </button>
                        </form>
                    </div> */}
                    <div className='logo-rodape'>
                        <a href='https://www2.petros.com.br' target='_blank' rel="noreferrer">
                            {/* backgroundColor: '#00112f', */}
                            <img style={{ padding: '20px' }} className='img-logo' src={LogoFoter} ></img>
                        </a>
                    </div>
                </div>
                : null}


        </div>

    )
}
